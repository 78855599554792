import React from "react"

import { FormattedMessage, injectIntl, navigate } from "gatsby-plugin-intl"

import Layout from "../layouts/layout"
import SEO from "../components/seo"

import Herofooter from "../components/Herofooter"

const TermsServicesPage = ( {intl} ) => (
  <Layout>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: "SEO.LANDING.TERMS_SERVICE.TITLE" })}
        description={intl.formatMessage({ id: "SEO.LANDING.TERMS_SERVICE.DESCRIPTION" })}
        keywords={intl.formatMessage({ id: "SEO.LANDING.TERMS_SERVICE.KEYWORDS" })}
      />

    <div className="tpnet-main-content">
      <tpnet-content>
        <article className="tpnet-article">
          <article className="tpnet-article-inner">
            <div className="tpnet-article-body">
              <main>
                <section className="landing-header">
                  <div
                    className="landing-header__nacho landing-header__nacho--default"
                    aria-hidden="true"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="3463.97px"
                      height="3947.35px"
                      viewBox="0 0 3463.97 3947.35"
                      preserveAspectRatio="xMinYMin"
                    >
                      <defs>
                        <clipPath id="landing-header-nacho_svg__clip-path">
                          <path
                            className="landing-header-nacho_svg__cls-1"
                            d="M-3593.5-2560.43c286.06-63.44 1878.61 2088.8 1793.43 2324.85s-2266.29 720.51-2442.7 542.47c-26-26.3-36.38-105.87-34.34-222.62 11.69-674.45 439.65-2590.53 683.61-2644.7z"
                          ></path>
                        </clipPath>
                        <clipPath id="landing-header-nacho_svg__clip-path-2">
                          <path
                            fill="none"
                            d="M-5206.6-3363.17h4882v3709h-4882z"
                          ></path>
                        </clipPath>
                        <clipPath id="landing-header-nacho_svg__clip-path-3">
                          <path
                            className="landing-header-nacho_svg__cls-1"
                            d="M33.7 3869.38C259.33 4130.56 3298.85 3677.6 3437.6 3362c20.45-46.59-5.48-153.88-65.65-302.79C3024.47 2198.87 1530.45-50.73 1190.5 0 791.92 59.49-192 3608.24 33.7 3869.38z"
                          ></path>
                        </clipPath>
                      </defs>
                      <g
                        clipPath="url(#landing-header-nacho_svg__clip-path)"
                        id="landing-header-nacho_svg__header-nacho"
                      >
                        <g clipPath="url(#landing-header-nacho_svg__clip-path-2)">
                          <path d="M-6731.56-24594.94l-221.11 27884.15L4247.1 3719.53l221.12-27884.15-11199.78-430.32z"></path>
                        </g>
                      </g>
                      <g
                        clipPath="url(#landing-header-nacho_svg__clip-path-3)"
                        id="landing-header-nacho_svg__landing-header-nacho"
                      >
                        <path
                          transform="rotate(-22 1175.988 2165.688)"
                          id="landing-header-background"
                          fill="currentColor"
                          d="M-530.36 155.86h3412.69v4019.63H-530.36z"
                        ></path>
                      </g>
                    </svg>
                  </div>
                  <header className="landing-header__text">
                    <div className="landing-header__inner-wrapper">
                      <tpnet-heading className="light">
                        <h1>
                          <FormattedMessage id="LANDING.TERMS_SERVICE"/>
                        </h1>
                      </tpnet-heading>
                    </div>
                  </header>
                </section>
                <div className="content-wrapper">
                  <section className="card">
                    <div className="text-block">
                      <strong>
                        <p>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r001"/>
                        </p>
                      </strong>
                      <strong>
                        <p><FormattedMessage id="LANDING.PAGE_TERMS.r002"/></p>
                      </strong>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r003"/>
                      </p>
                      <strong>
                        <p><FormattedMessage id="LANDING.PAGE_TERMS.r004"/></p>
                      </strong>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r005"/>
                      </p>
                      <strong>
                        <p><FormattedMessage id="LANDING.PAGE_TERMS.r006"/></p>
                      </strong>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r007"/>
                      </p>
                      <strong>
                        <p>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r008"/>
                        </p>
                      </strong>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r009"/>
                      </p>
                      <strong>
                        <p><FormattedMessage id="LANDING.PAGE_TERMS.r010"/></p>
                      </strong>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r011"/>
                      </p>
                      <strong>
                        <p><FormattedMessage id="LANDING.PAGE_TERMS.r012"/></p>
                      </strong>
                      <strong>
                        <p>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r013"/>
                        </p>
                      </strong>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r014"/>
                      </p>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r015"/>
                      </p>
                      <strong>
                        <p>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r016"/>
                        </p>
                      </strong>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r017"/>
                      </p>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r018"/>
                      </p>
                      <strong>
                        <p><FormattedMessage id="LANDING.PAGE_TERMS.r019"/></p>
                      </strong>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r020"/>
                      </p>
                      <ul>
                        <li>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r021"/>
                        </li>
                        <li>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r022"/>
                        </li>
                        <li>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r023"/>
                        </li>
                        <li>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r024"/>
                        </li>
                      </ul>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r025"/>
                      </p>
                      <strong>
                        <p>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r026"/>
                        </p>
                      </strong>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r027"/>
                      </p>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r028"/>
                      </p>
                      <strong>
                        <p><FormattedMessage id="LANDING.PAGE_TERMS.r029"/></p>
                      </strong>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r030"/>
                      </p>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r031"/>
                      </p>
                      <strong>
                        <p>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r032"/>
                        </p>
                      </strong>
                      <strong>
                        <p>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r033"/>
                        </p>
                      </strong>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r034"/>
                      </p>
                      <ul>
                        <li>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r035"/>
                        </li>
                        <li>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r036"/>
                        </li>
                        <li>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r037"/>
                        </li>
                        <li>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r038"/>
                        </li>
                      </ul>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r039"/>
                      </p>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r040"/>
                      </p>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r041"/>
                      </p>
                      <strong>
                        <p>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r042"/>
                        </p>
                      </strong>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r043"/>
                      </p>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r044"/>
                      </p>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r045"/>
                      </p>
                      <strong>
                        <p>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r046"/>
                        </p>
                      </strong>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r047"/>
                      </p>
                      <strong>
                        <p>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r048"/>
                        </p>
                      </strong>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r049"/>
                      </p>
                      <strong>
                        <p><FormattedMessage id="LANDING.PAGE_TERMS.r050"/></p>
                      </strong>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r051"/>
                      </p>
                      <strong>
                        <p>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r052"/>
                        </p>
                      </strong>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r053"/>
                      </p>
                      <strong>
                        <p>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r054"/>
                        </p>
                      </strong>
                      <strong>
                        <p>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r055"/>
                        </p>
                      </strong>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r056"/>
                      </p>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r057"/>
                      </p>
                      <strong>
                        <p>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r058"/>
                        </p>
                      </strong>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r059"/>
                      </p>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r060"/>
                      </p>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r061"/>
                      </p>
                      <strong>
                        <p><FormattedMessage id="LANDING.PAGE_TERMS.r062"/></p>
                      </strong>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r063"/>
                      </p>
                      <ul>
                        <li>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r064"/>
                        </li>
                        <li>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r065"/>
                        </li>
                        <li>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r066"/>
                        </li>
                        <li>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r067"/>
                        </li>
                      </ul>
                      <strong>
                        <p>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r068"/>
                        </p>
                      </strong>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r069"/>
                      </p>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r070"/>
                      </p>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r071"/>
                      </p>
                      <p>
                      <FormattedMessage id="LANDING.PAGE_TERMS.r072"/>
                      </p>
                      <strong>
                        <p>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r073"/>
                        </p>
                      </strong>
                      <strong>
                        <p><FormattedMessage id="LANDING.PAGE_TERMS.r074"/></p>
                      </strong>
                      <strong>
                        <p>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r075"/>
                        </p>
                      </strong>
                      <ul>
                        <li>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r076"/>
                        </li>
                        <li>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r077"/>
                        </li>
                        <li>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r078"/>
                        </li>
                        <li>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r079"/>
                        </li>
                      </ul>
                      <strong>
                        <p><FormattedMessage id="LANDING.PAGE_TERMS.r080"/></p>
                      </strong>
                      <strong>
                        <p>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r081"/>
                        </p>
                      </strong>
                      <ul>
                        <li>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r082"/>
                        </li>
                        <li>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r083"/>
                        </li>
                        <li>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r084"/>
                        </li>
                        <li>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r085"/>
                        </li>
                        <li>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r086"/>
                        </li>
                        <li>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r087"/>
                        </li>
                        <li>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r088"/>
                        </li>
                        <li>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r089"/>
                        </li>
                        <li>
                        <FormattedMessage id="LANDING.PAGE_TERMS.r090"/>
                        </li>
                        <li><FormattedMessage id="LANDING.PAGE_TERMS.r091"/>
                        </li>
                        <li><FormattedMessage id="LANDING.PAGE_TERMS.r092"/>
                        </li>
                        <li><FormattedMessage id="LANDING.PAGE_TERMS.r093"/>
                        </li>
                        <li><FormattedMessage id="LANDING.PAGE_TERMS.r094"/>
                        </li>
                        <li><FormattedMessage id="LANDING.PAGE_TERMS.r095"/>
                        </li>
                        <li><FormattedMessage id="LANDING.PAGE_TERMS.r096"/>
                        </li>
                        <li><FormattedMessage id="LANDING.PAGE_TERMS.r097"/>
                        </li>
                        <li><FormattedMessage id="LANDING.PAGE_TERMS.r098"/>
                        </li>
                        <li><FormattedMessage id="LANDING.PAGE_TERMS.r099"/>
                        </li>
                        <li><FormattedMessage id="LANDING.PAGE_TERMS.r100"/>
                        </li>
                        <li><FormattedMessage id="LANDING.PAGE_TERMS.r101"/>
                        </li>
                        <li><FormattedMessage id="LANDING.PAGE_TERMS.r102"/>
                        </li>
                        <li><FormattedMessage id="LANDING.PAGE_TERMS.r103"/>
                        </li>
                        <li><FormattedMessage id="LANDING.PAGE_TERMS.r104"/>
                        </li>
                        <li><FormattedMessage id="LANDING.PAGE_TERMS.r105"/>
                        </li>
                        <li><FormattedMessage id="LANDING.PAGE_TERMS.r106"/>
                        </li>
                        <li><FormattedMessage id="LANDING.PAGE_TERMS.r107"/>
                        </li>
                        <li><FormattedMessage id="LANDING.PAGE_TERMS.r108"/>
                        </li>
                        <li><FormattedMessage id="LANDING.PAGE_TERMS.r109"/>
                        </li>
                        <li><FormattedMessage id="LANDING.PAGE_TERMS.r110"/>
                        </li>
                        <li><FormattedMessage id="LANDING.PAGE_TERMS.r111"/>
                        </li>
                        <li><FormattedMessage id="LANDING.PAGE_TERMS.r112"/>
                        </li>
                        <li><FormattedMessage id="LANDING.PAGE_TERMS.r113"/>
                        </li>
                        <li><FormattedMessage id="LANDING.PAGE_TERMS.r114"/>
                        </li>
                        <li><FormattedMessage id="LANDING.PAGE_TERMS.r115"/>
                        </li>
                        <li><FormattedMessage id="LANDING.PAGE_TERMS.r116"/>
                        </li>
                        <li><FormattedMessage id="LANDING.PAGE_TERMS.r117"/>
                        </li>
                        <li><FormattedMessage id="LANDING.PAGE_TERMS.r118"/>
                        </li>
                      </ul>
                    </div>
                  </section>
                </div>

                <Herofooter/>

              </main>
            </div>
          </article>
        </article>
      </tpnet-content>
    </div>
  </Layout>
)

export default injectIntl(TermsServicesPage)
